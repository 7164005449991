<template>
  <b-skeleton-wrapper :loading="loading">
    <template #loading>
      <b-card-body class="statistics-body">
        <div class="d-flex mb-1 justify-start">
          <div class="mr-1">
            <b-skeleton
              type="button"
              width="200px"
              height="55px"
            />
          </div>
          <div class="mr-1">
            <b-skeleton
              type="button"
              width="200px"
              height="55px"
            />
          </div>
          <div class="mr-1">
            <b-skeleton
              type="button"
              width="200px"
              height="55px"
            />
          </div>
        </div>
        <b-skeleton-img
          no-aspect
          height="475px"
        />
      </b-card-body>
    </template>
    <b-card-body class="statistics-body">
      <div>
        <b-tabs
          v-if="tabs.length !== 0"
        >
          <div
            v-for="item in tabs"
            :key="item.icon"
          >
            <b-tab
              v-if="item.title !== 0"
              pills
              @click="$emit('itemField', item.field)"
            >
              <template #title>
                <div>
                  <b-card-text class="font-small-3 mb-0">
                    {{ item.subtitle === 'Impressions'
                      ? $t('reportsTableHeaders.site.impressions')
                      : item.subtitle === 'Clicks'
                        ? $t('reportsTableHeaders.site.clicks')
                        : $t('reportsTableHeaders.sites.amount')
                    }}
                  </b-card-text>
                  <h2>{{ item.title }}</h2>
                </div>
              </template>
              <top-statistic-chart
                v-if="renderComponent"
                :rows="chartData"
                :field="item.field"
                :account-currency="accountCurrency"
              />
            </b-tab>
          </div>
        </b-tabs>
        <div v-else>
          <b-alert
            variant="secondary"
            class="mt-2"
            show
          >
            <div class="alert-body text-center">
              <span>{{ $t('No data for this period') }}</span>
            </div>
          </b-alert>
        </div>
      </div>
    </b-card-body>

  </b-skeleton-wrapper>
</template>

<script>
import getFormattedDate from '@/utils/date-formatter'
import currencyFormatter from '@/utils/currency-formatter'
import Ripple from 'vue-ripple-directive'
import {
  BCardText,
  BCardBody,
  BAlert,
  // BBadge,
  VBTooltip,
  BTabs,
  BTab,
  BSkeletonWrapper,
  BSkeleton,
  BSkeletonImg,
} from 'bootstrap-vue'
import TopStatisticChart from './TopStatisticChart.vue'

export default {
  components: {
    BCardText,
    BCardBody,
    BAlert,
    BTabs,
    BTab,
    TopStatisticChart,
    BSkeletonWrapper,
    BSkeleton,
    BSkeletonImg,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    cardTitle: {
      type: String,
      required: true,
    },
    statsData: {
      type: Array,
      required: true,
    },
    accountCurrency: {
      type: String,
      default: '',
    },
  },
  emits: ['itemField'],
  data() {
    return {
      renderComponent: true,
      loading: true,
      getFormattedDate,
      currency: currencyFormatter,
      chartData: [],
      tabs: [],
    }
  },
  watch: {
    forceRerender() {
      this.renderComponent = false

      this.$nextTick(() => {
        this.renderComponent = true
      })
    },
  },
  created() {
    this.complate()
  },
  methods: {
    complate() {
      this.setStats()
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    tooltipFormat(data, type) {
      let text = ''
      Object.keys(data).forEach(i => {
        if (type === 'amount') {
          text += `${data[i].date}: ${this.currency(this.accountCurrency, data[i][type])}\n\t`
        } else {
          text += `${data[i].date}: ${new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(data[i][type])}\n\t`
        }
      })
      return text
    },
    setStats() {
      const totalClicks = this.totalFormat('click', this.statsData)
      const totalShows = this.totalFormat('show', this.statsData)
      const totalAmount = this.totalFormat('amount', this.statsData)

      this.chartData = this.statsData

      this.tabs.push(
        {
          icon: 'TrendingUpIcon',
          color: 'light-primary',
          title: totalShows ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(totalShows) : 0,
          subtitle: 'Impressions',
          field: 'show',
          customClass: 'mb-2',
        },
        {
          icon: 'MousePointerIcon',
          color: 'light-info',
          title: totalClicks ? new Intl.NumberFormat('en-GB', { style: 'decimal' }).format(totalClicks) : 0,
          subtitle: 'Clicks',
          field: 'click',
          customClass: 'mb-2',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-success',
          title: totalAmount ? this.currency(this.accountCurrency, totalAmount) : 0,
          subtitle: 'Income',
          field: 'amount',
          customClass: 'mb-2',
        },
      )
    },
    totalFormat(field, rows) {
      let total = 0
      rows.forEach(i => {
        total += i[field]
      })
      if (field === 'amount') {
        return +total.toPrecision(2)
      }
      return total
    },
  },
}
</script>
<style scoped>
[dir] #select_type.fixed {
    position: fixed;
    top: 15%;
    right: 50px;
    z-index: 99;
    border-radius: 0.358rem;
    background: #fff;
}

[dir] .card-statistics .nav-tabs .nav-link.active {
  background-color: #f7f7f7 !important;
}

[dir] .nav-tabs .nav-link.active {
  background-color: #f7f7f7 !important;
}

.card-statistics {
  height: 100%;
}
.card-statistics .mb-2:last-child {
  margin-bottom: 0 !important;
}

.statistics-body {
  padding-bottom: 0 !important;
  position: relative;
  overflow: hidden;
}

@media (max-width: 768px) {
  .card-statistics {
    height: auto;
  }

  .statistics-body {
    padding-bottom: 2.8rem !important;
  }
}
</style>
<style scoped lang="scss">
@media (max-width: 560px) {
  [dir] #app {
    .nav-tabs h2{
      font-size: 1.5rem;
    }

    .card-statistics {
      .statistics-body {
        padding: 1.4rem 0  0!important;
      }
    }
  }
}

@media (max-width: 470px) {
  [dir] #app {
    .nav-tabs h2{
      font-size: 1.2rem;
    }

    .card-statistics {
      .statistics-body {
        padding: 1.4rem 0  0!important;
      }
    }
  }
}
</style>
